import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Sidebar from '../components/Sidebar'
import { Icon } from '../components/Icon'

const Page: FC<PropsWithChildren> = () => {
  const location = useLocation()

  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    document.body.scrollTop = 0

    const titles = [
      {
        path: '/venues',
        title: 'Event Venues',
      },
      {
        path: '/events',
        title: 'Add Event'
      }
    ]

    setTitle(titles.find(t => {
      return t.path === location.pathname
    })?.title || '')

  }, [location.pathname, setTitle])

  useEffect(() => {
    const handleWheel = () => {
      const numberInput = document.activeElement as HTMLInputElement
      if (numberInput?.type === 'number') {
        numberInput.blur()
      }
    }
    document.addEventListener('wheel', handleWheel)
    return () => {
      document.removeEventListener('wheel', handleWheel)
    }
  }, [])

  return (
    <div className='w-full'>
      <div className='flex'>
        <Sidebar />
        <div className="flex flex-col main-content">
          <div className='flex px-[20px] py-[28px] justify-between items-center shadow-[0_4px_14px_0_rgba(0,0,0,0.15)] mb-[20px]'>
            <div className='flex gap-[10px] items-center'>
              <Icon icon='back_circle' width='20px' height='20px' viewBox='0 0 20 20' />
              <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#212529]'>Back</span>
            </div>
            <span className='text-helvetica text-[24px] font-[700] leading-[24px] text-[#212529]'>{title}</span>
            <a href='//admin.staging.extasy.com/logout'>
              <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#212529]'>Logout</span>
            </a>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Page
