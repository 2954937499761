import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  placeholder?: string;
  options: { value: string; label: string }[];
  error?: string;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      placeholder,
      options,
      value,
      className,
      onChange,
      required = false,
      error,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="mb-2 flex flex-col flex-1">
        {!!label && (
          <label
            className="block capitalize text-gray-700 text-sm font-bold mb-2"
            htmlFor={label}
          >
            {label}
          </label>
        )}

        <select
          id={label}
          ref={ref}
          value={value}
          onChange={onChange}
          required={required}
          className={twMerge(
            "shadow appearance-none border border-input-border bg-input-background rounded-lg w-full py-2 px-3 pe-10 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-500",
            error && "border-red-500",
            className
          )}
          style={{
            backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20" fill="none" stroke="gray" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"%3E%3Cpolyline points="6 9 12 15 18 9"%3E%3C/polyline%3E%3C/svg%3E')`,
            backgroundPosition: "right 1rem center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "1rem",
          }}
          {...rest}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && <span className="text-red-500 text-xs italic">{error}</span>}
      </div>
    );
  }
);

Select.displayName = "Select";

export default Select;
