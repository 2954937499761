import { useCallback, useEffect, useRef, useState } from "react"
import { City, Country, Location, Locations, Version } from "../models"
import { Icon } from "../components/Icon"
import { IColumn, Table, TableSearchParams } from "../components/Table"
import { DeleteConfirmationModal } from "../components/DeleteConfirmationModal"
import { AddNewLocationModal } from "../components/AddNewLocationModal"
import { AddNewVersionModal } from "../components/AddNewVersionModal"

export const EventVenuesPage = () => {
  const [venues, setVenues] = useState<Locations[]>([])
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null)
  const [currentVersion, setCurrentVersion] = useState<Version | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const searchParams = useRef<TableSearchParams>({})
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [allCountries, setAllCountries] = useState<Country[]>([])
  const [allCities, setCities] = useState<City[]>([])
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)
  const [isOpen, setIsOpen] = useState<string[]>([])
  const [selectedCity, setSelectedCity] = useState<City | null>(null)

  const [showAddNewLocation, setShowNewLocation] = useState<boolean>(false)
  const [showAddNewVersion, setShowNewVersion] = useState<boolean>(false)
  const [versionParentId, setVersionParentId] = useState<string>('')
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deleteText, setDeleteText] = useState<string>('')
  const [deleteId, setDeleteId] = useState<string>('')

  useEffect(() => {
    // get all events
    const mock: Locations[] = [
      {
        id: '1',
        name: 'Sala cu Orgă',
        country: '1',
        city: '1',
        versions: []
      },
      {
        id: '2',
        name: 'Sala Polivalentă',
        country: '1',
        city: '2',
        versions: [
          {
            id: '2-1',
            name: 'Sala Polivalentă (UNTOLD)',
          },
          {
            id: '2-2',
            name: 'Sala Polivalentă (EXTASY EVENT)',
          },
        ]
      },
    ]

    setVenues(mock)
  }, [selectedCountry, selectedCity])

  useEffect(() => {
    // get All countries
    setAllCountries([{ id: '1', name: 'Romania', locale: 'ro' }, { id: '2', name: 'USA', locale: 'en' }])
  }, [])

  useEffect(() => {
    if (selectedCountry !== null) {
      // get all cities in a country
      setCities([{ id: '1', name: 'Cluj-Napoca' }, { id: '2', name: 'Bucuresti' }])
    }
  }, [selectedCountry])

  const handleClick = (id: string) => {
    if (isOpen.includes(id)) {
      setIsOpen(isOpen.filter((item) => item !== id))
    } else {
      setIsOpen([...isOpen, id])
    }
  }

  const handleDuplicate = (id: string) => {
    console.log("duplicate Call here")
  }

  const handleDelete = () => {
    console.log(`handle delete call for ${deleteId}`)
  }

  const columns: IColumn[] = [
    {
      title: '',
      accessor: '',
      render: (row) => (
        <div className="h-full items-center flex">
          <button onClick={() => handleClick(row.id)} className="h-[41px] px-[10px] pt-[8px] pb-[9px]" disabled={row.versions.length === 0}>
            {isOpen.includes(row.id) ?
              <Icon icon="chevron-up"
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
                fill="#ADB5BD" />
              :
              <Icon icon="chevron-down"
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
                fill="#ADB5BD" />
            }
          </button>
        </div>
      ),
      width: '16px',
    },
    {
      title: 'Venue',
      accessor: 'venue',
      searchable: true,
      sortable: true,
      render: (row) => {
        return (
          <div className="flex flex-col gap-[10px]">
            <div className="px-[8px] pt-[8px] pb-[9px]">{row.name}</div>
            {isOpen.includes(row.id) &&
              row.versions.map((version: any) =>
                <div key={version.id} className="px-[8px] pt-[8px] pb-[9px]">{version.name}</div>
              )
            }
          </div>
        )
      },
      width: 'auto'
    },
    {
      title: 'Country',
      accessor: 'country',
      searchable: true,
      sortable: true,
      render: (row) => <div className="px-[8px] pt-[8px] pb-[9px] self-strech">{row.country}</div>,
      width: '280px',
    },
    {
      title: 'City',
      accessor: 'city',
      searchable: true,
      sortable: true,
      render: (row) => <div className="px-[8px] pt-[8px] pb-[9px] self-strech">{row.city}</div>,
      width: '280px',
    },
    {
      title: 'Edit',
      accessor: '',
      render: (row) => (
        <div className="flex flex-col gap-[10px]">
          <div className="flex">
            <button className="bg-inherit text-[#6f42c1] border-0 underline px-[12px] pt-[8px] pb-[9px]" onClick={() => {
              //get full data for row here then set current location
              setCurrentLocation(row)
              setShowNewLocation(true)
            }
            }>Edit</button>
            <button className="bg-inherit text-[#6f42c1] border-0 underline px-[12px] pt-[8px] pb-[9px]" onClick={() => handleDuplicate(row.id)}>Duplicate</button>
            <button className="bg-inherit text-[#DC3545] border-0 underline px-[12px] pt-[8px] pb-[9px]" onClick={() => {
              setDeleteText(row.name)
              setDeleteId(row.id)
              setShowDeleteModal(true)
            }}>Delete</button>
            <button className="px-[12px] py-[6px] bg-[#DEE2E6] rounded-[4px] border border-[#DEE2E6]" onClick={() => {
              setShowNewVersion(true)
              setVersionParentId(row.id)
            }}>Add Version</button>
          </div>
          {
            isOpen.includes(row.id) &&
            row.versions.map((version: any) =>
              <div className="flex" key={version.id}>
                <button className="bg-inherit text-[#6f42c1] border-0 underline px-[12px] pt-[8px] pb-[9px]" onClick={() => {
                  //get full data for row here then set current version
                  setCurrentVersion(version)
                  setVersionParentId(row.id)
                  setShowNewVersion(true)
                }
                }>Edit</button>
                <button className="bg-inherit text-[#6f42c1] border-0 underline px-[12px] pt-[8px] pb-[9px]" onClick={() => handleDuplicate(version.id)}>Duplicate</button>
                <button className="bg-inherit text-[#DC3545] border-0 underline px-[12px] pt-[8px] pb-[9px]" onClick={() => {
                  setDeleteText(version.name)
                  setDeleteId(version.id)
                  setShowDeleteModal(true)
                }}>Delete</button>
              </div>
            )
          }
        </div >
      ),
      width: '358px',
    },

  ]

  const handleSortBy = (sort: string) => {

  }

  const handleSearchParams = useCallback(
    async (col: IColumn, q: string | null) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }

      searchParams.current = {
        [col.accessor]: q,
      }

      const newTimer = setTimeout(async () => {
        setLoading(true)
        try {
          // get data here
          // setVenues()
        } catch (err) {
          console.log(err)
        } finally {
          setLoading(false)
        }
      }, 2000)
      timer.current = newTimer
    },
    [selectedCity, selectedCountry],
  )


  return (
    <div className="flex flex-col px-[20px] w-full">
      <div className="flex gap-[20px]">
        <div className="flex flex-col gap-[10px] w-full">
          <span className='text-[16px] font-[700] leading-[24px] text-helvetica'>Country</span>
          <select className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
            onChange={(e) => setSelectedCountry(allCountries.filter(country => country.id === e.target.value)[0])}
          >
            {
              allCountries.map(country => {
                return (
                  <option key={country.id} value={country.id}>{country.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className="flex flex-col gap-[10px] w-full">
          <span className={`text-[16px] font-[700] leading-[24px] opacity-${selectedCountry !== null ? '100' : '50'} text-helvetica`}>City</span>
          <select disabled={selectedCountry === null} className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
            onChange={(e) => setSelectedCity(allCities.filter(city => city.id === e.target.value)[0])}>
            {
              allCities.map(city => {
                return (
                  <option key={city.id} value={city.id}>{city.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className="flex items-end h-[72px]">
          <button className="bg-[#6F42C1] h-[38px] flex gap-[8px] items-center px-[16px] py-[8px] justify-center rounded-[4px] min-w-[154px] text-[#fff]">
            <>
              <Icon icon="add"
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
                fill='#FFF' />
              <span className="text-[16px] font-[400] leading-[24px] text-[#fff] text-helvetica" onClick={() => setShowNewLocation(true)}>Add Location</span>
            </>
          </button>
        </div>
      </div>
      <Table
        handleSortBy={handleSortBy}
        handleSearch={(col, query) => {
          handleSearchParams(col, query)
        }}
        columns={columns}
        data={venues}
      />
      <AddNewLocationModal
        showAddNewLocation={showAddNewLocation}
        setShowNewLocation={setShowNewLocation}
        allCountries={allCountries}
        location={currentLocation}
      />
      <AddNewVersionModal
        showAddNewVersion={showAddNewVersion}
        setShowNewVersion={setShowNewVersion}
        versionParentId={versionParentId}
        version={currentVersion}

      />
      <DeleteConfirmationModal
        showDeleteConfirmationModal={showDeleteModal}
        setDeleteConfirmationModal={setShowDeleteModal}
        deleteTitle={deleteText}
        deleteFunc={handleDelete}
      />
      <DeleteConfirmationModal
        showDeleteConfirmationModal={showDeleteModal}
        setDeleteConfirmationModal={setShowDeleteModal}
        deleteTitle={deleteText}
        deleteFunc={handleDelete}
      />

    </div>
  )
}