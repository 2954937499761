import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Icon } from "../components/Icon";
import { AddArea } from "../components/SeatsBuilder/AddArea";
import { SeatsMap } from "../components/SeatsBuilder/SeatsMap";
import Button from "../components/ui/button";

export const SeatsBuilderPage = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    fetch("/api/countries");
  }, []);

  return (
    <div
      className={twMerge(
        "p-4 h-screen bg-gray-50 grid gap-4 overflow-hidden",
        sidebarVisible ? "grid-cols-[339px_1fr]" : "grid-cols-[75px_1fr]"
      )}
    >
      {sidebarVisible ? (
        <div className="bg-white p-4 rounded-3xl shadow-2xl overflow-y-auto">
          <AddArea toggle={setSidebarVisible} />
        </div>
      ) : (
        <div className="bg-white rounded-3xl shadow-2xl overflow-hidden">
          <Button
            variant="secondary"
            className="w-full h-full flex items-center justify-center bg-transparent hover:bg-transparent rotate-180"
            onClick={() => {
              setSidebarVisible(true);
            }}
          >
            <Icon
              icon="sidebar-arrow"
              width="22px"
              height="30px"
              viewBox="0 0 12 20"
              fill="black"
            />
          </Button>
        </div>
      )}
      <div
        className={twMerge(
          "bg-white p-4 rounded-3xl shadow-2xl relative flex flex-col flex-1 overflow-hidden"
        )}
      >
        <SeatsMap />
      </div>
    </div>
  );
};
