interface CircleSeatProps {
  angle: number;
  radius: number;
  size: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CircleSeat: React.FC<CircleSeatProps> = ({
  angle,
  radius,
  size,
  onClick,
}) => {
  const x = radius * Math.cos(angle) + radius;
  const y = radius * Math.sin(angle) + radius;

  return (
    <button
      className="circle"
      onClick={(e) => {
        onClick?.(e);
        console.log("asdasd");
      }}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: "absolute",
        top: `${y}px`,
        left: `${x}px`,
        transform: "translate(-50%, -50%)",
        backgroundColor: "yellow",
        borderRadius: "50%",
      }}
    />
  );
};
