import { CSSProperties } from "react";

interface CircleSeatProps {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  size: number;
}

export const SquareSeat: React.FC<CircleSeatProps> = ({
  top,
  left,
  bottom,
  right,
  size,
}) => {
  const style: CSSProperties = {};
  if (top) {
    style["top"] = `${top}px`;
  }
  if (left) {
    style["left"] = `${left}px`;
  }
  if (right) {
    style["right"] = `${right}px`;
  }
  if (bottom) {
    style["bottom"] = `${bottom}px`;
  }

  return (
    <button
      style={{
        width: `${size}px`,
        height: `${size}px`,
        ...style,
      }}
      className="absolute bg-yellow-500 rounded-full"
    />
  );
};
