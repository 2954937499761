import React, { forwardRef, useId } from "react";
import { twMerge } from "tailwind-merge";

// Extend the built-in Input element props (React.InputHTMLAttributes)
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type = "text",
      placeholder = "",
      value,
      onChange,
      required = false,
      error,
      className,
      ...rest
    },
    ref
  ) => {
    const id = useId();
    return (
      <div className="mb-2 flex flex-col flex-1">
        {label && (
          <label
            className="block capitalize text-gray-700 text-sm font-bold mb-2"
            htmlFor={`${label}-${id}`}
          >
            {label}
          </label>
        )}
        <input
          id={`${label}-${id}`}
          ref={ref}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          className={twMerge(
            "shadow appearance-none border border-input-border bg-input-background rounded-md w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-700",
            error && "border-red-500",
            className
          )}
          {...rest}
        />
        {error && <span className="text-red-500 text-xs italic">{error}</span>}
      </div>
    );
  }
);

// Provide a display name for the component for debugging purposes
Input.displayName = "Input";

export default Input;
