
export interface DeleteConfirmationModalProps {
  showDeleteConfirmationModal: boolean
  setDeleteConfirmationModal: (show: boolean) => void
  deleteFunc: () => void
  cancelText?: boolean
  deleteTitle?: string
  onClose?: () => void
}

export const DeleteConfirmationModal = (
  props: DeleteConfirmationModalProps,
): JSX.Element => {
  const {
    showDeleteConfirmationModal,
    setDeleteConfirmationModal,
    deleteFunc,
    deleteTitle,
    onClose,
    cancelText = false,
  } = props

  const handleClose = () => {
    setDeleteConfirmationModal(false)
    onClose?.()
  }

  const handleDelete = () => {
    deleteFunc()
    handleClose()
  }

  return (
    <div className={`fixed left-0 top-0 bg-black bg-opacity-50 w-screen h-screen justify-center items-center ${showDeleteConfirmationModal ? 'flex' : 'hidden'}`} onClick={handleClose}>
      <div className="bg-white rounded shadow-md w-[40%]" onClick={(e) => { e.stopPropagation() }}>
        <div className="flex flex-col">
          <span className="text-[24px] font-[700] leading-[24px] text-helvetica border-b border-b-[#dee2e6] p-[16px] text-center">
            {`Delete ${deleteTitle}`}
          </span>
          <span className="text-[16px] font-[400] leading-[24px]  text-helvetica p-[16px] border-b border-b-[#dee2e6] text-center">
            Are you sure you want to {cancelText ? 'cancel' : 'delete'}?
          </span>
          <div className="flex justify-end gap-[10px] p-[16px]">
            <button
              onClick={handleClose}
              className="px-[12px] py-[6px] bg-[#DEE2E6] rounded-[4px] border border-[#DEE2E6]"
            >
              No
            </button>
            <button
              className="text-[16px] font-[400] rounded leading-[24px] text-[#fff] text-helvetica bg-[#DC3545] px-[16px] py-[8px]"
              onClick={handleDelete}
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
