import { DndContext, useDroppable } from "@dnd-kit/core";
import { useState } from "react";
import { Icon } from "../Icon";
import Button from "../ui/button";
import { CircleArea } from "./CircleArea";
import { SquareArea } from "./SquareArea";
import { AddNewArea } from "./AddAreaModal";

export const SeatsMap = () => {
  const [zoom, setZoom] = useState(1);
  const { setNodeRef, node } = useDroppable({
    id: "droppable",
  });

  const [addNewArea, setAddNewArea] = useState(false);

  const [areas, setAreas] = useState([
    { type: "circle", numSeats: 12, id: "1", x: 0, y: 0, scale: 1 },
    { type: "circle", numSeats: 14, id: "5", x: 100, y: 100, scale: 0.2 },
    {
      type: "square",
      numSeatsPerSide: 4,
      id: "2",
      x: 300,
      y: 300,
      scale: 0.7,
    },
    {
      type: "square",
      numSeatsPerSide: 4,
      id: "4",
      x: 700,
      y: 600,
      scale: 0.7,
    },
    {
      type: "square",
      numSeatsPerSide: 6,
      id: "15",
      x: 200,
      y: 200,
      scale: 0.7,
    },
    { type: "circle", numSeats: 6, id: "3", x: 122, y: 329, scale: 0.7 },
  ]);

  // Function to get the scale factor from the transform style
  const getScale = (element: HTMLElement) => {
    const transform = window.getComputedStyle(element).transform;
    if (transform && transform !== "none") {
      const match = transform.match(/matrix\(([^)]+)\)/);
      if (match) {
        const values = match[1].split(", ");
        const scaleX = parseFloat(values[0]);
        const scaleY = parseFloat(values[3]);
        return { scaleX, scaleY };
      }
    }
    return { scaleX: 1, scaleY: 1 };
  };

  return (
    <>
      <DndContext
        onDragEnd={(event) => {
          const draggedId = event.active.data?.current?.id;
          const current = event.active.data?.current?.ref;

          if (!draggedId) return;

          const area = areas.find((area) => area.id === draggedId);

          if (!area) return;

          if (!node.current) return;
          if (!current) return;

          const parentRect = node.current.getBoundingClientRect();
          const currentRect = current.getBoundingClientRect();

          // Get the scale factor of the container
          const { scaleX, scaleY } = getScale(node.current);

          // Adjust the calculations by dividing by the scale
          const newX = (currentRect.left - parentRect.left) / scaleX;
          const newY = (currentRect.top - parentRect.top) / scaleY;

          setAreas((prevAreas) =>
            prevAreas.map((prevArea) =>
              prevArea.id === draggedId
                ? {
                    ...prevArea,
                    x: newX,
                    y: newY,
                  }
                : prevArea
            )
          );
        }}
      >
        <div className="relative h-[calc(100vh-70px)]">
          <div className="flex justify-between items-center">
            <Button
              onClick={() => setAddNewArea(true)}
              className="flex items-center gap-2"
            >
              <>
                <Icon
                  icon="add"
                  width="16px"
                  height="16px"
                  viewBox="0 0 16 16"
                />
                Add New Area
              </>
            </Button>
            <Button className="flex items-center gap-2">
              <>
                <Icon
                  icon="areas"
                  width="16px"
                  height="14px"
                  viewBox="0 0 16 14"
                />
                Areas
              </>
            </Button>
          </div>
          <div className="absolute top-10 left-0 max-w-[1200px] max-h-[calc(100vh-100px)] overflow-auto">
            <div
              ref={setNodeRef}
              className="w-[1400px] h-[800px] relative border border-slate-950"
              style={{
                transform: `scale(${zoom})`,
                transformOrigin: "top left",
              }}
            >
              {areas.map((area, index) =>
                area.type === "circle" ? (
                  <CircleArea
                    key={index}
                    style={{
                      top: area.y,
                      left: area.x,
                      transform: `scale(${area.scale})`,
                      transformOrigin: "top left",
                      position: "absolute",
                    }}
                    numSeats={area.numSeats!}
                    areaId={area.id}
                    scale={area.scale}
                  />
                ) : (
                  <SquareArea
                    key={index}
                    style={{
                      top: area.y,
                      left: area.x,
                      transform: `scale(${area.scale})`,
                      transformOrigin: "top left",
                      position: "absolute",
                    }}
                    numSeatsPerSide={area.numSeatsPerSide!}
                    areaId={area.id}
                    scale={area.scale}
                  />
                )
              )}

              <img
                src="https://picsum.photos/1200"
                alt=""
                className="object-cover w-full h-full absolute top-0 left-0"
              />
            </div>
          </div>
          <div className="flex flex-col justify-end gap-2 px-4 absolute right-0 bottom-0 z-10">
            <Button
              className="w-[48px] h-[48px] justify-center items-center flex shrink-0 px-2"
              onClick={() => setZoom(1)}
            >
              <Icon
                icon="crosshair"
                width="22px"
                height="22px"
                viewBox="0 0 22 22"
              />
            </Button>
            <Button
              variant="secondary"
              className="w-[48px] h-[48px] justify-center items-center flex shrink-0 px-2"
              onClick={() => setZoom(zoom + 0.1)}
            >
              <Icon icon="add" width="16px" height="16px" viewBox="0 0 16 16" />
            </Button>
            <Button
              variant="secondary"
              className="w-[48px] h-[48px] justify-center items-center flex shrink-0 px-2"
              onClick={() => setZoom(zoom - 0.1)}
            >
              <Icon
                icon="minus"
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
              />
            </Button>
          </div>
        </div>
      </DndContext>
      <AddNewArea visible={addNewArea} setVisible={setAddNewArea} />
    </>
  );
};
