import { FC, useEffect, useRef, useState } from 'react'
import { Icon } from './Icon'

export type TableSearchParams = Record<string, string | null | undefined>

export interface IColumn {
  title: string | JSX.Element
  accessor: string
  sortable?: boolean
  width?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (row: any, index: number) => JSX.Element | null | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: (row: any) => React.CSSProperties
  searchable?: boolean
}

interface ITable {
  striped?: boolean | string;
  bordered?: boolean;
  borderless?: boolean;
  hover?: boolean;
  size?: string;
  variant?: string;
  responsive?: boolean | string;
  columns: IColumn[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  isCheck?: string[]
  handleSortBy?: (column: string) => void
  columnWidth?: string
  handleSearch?: (column: IColumn, query: string | null) => void
  loading?: boolean
  noDataMessage?: string | JSX.Element
  searchParams?: TableSearchParams
  alignment?: string
}

const NoDataComponent = ({
  message,
  colSpan,
}: {
  message?: string | JSX.Element
  colSpan?: number
}) => (
  <tr>
    <td colSpan={colSpan} className="text-center">
      {message || 'There is no data'}
    </td>
  </tr>
)

const ThElement = ({
  column,
  columnWidth,
  handleSortBy,
  handleSearch,
  query: queryProp,
}: {
  column: IColumn
  columnWidth?: string
  handleSortBy?: (column: string) => void
  handleSearch?: (column: IColumn, query: string | null) => void
  query?: string | null
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState(false)
  const [query, setQuery] = useState<string | null>('')
  const onChange = (val: string | null) => {
    setQuery(val)
    handleSearch?.(column, val)
  }

  useEffect(() => {
    if (queryProp) {
      setQuery(queryProp)
      setSearch(true)
    } else {
      setQuery(null)
      setSearch(false)
    }
  }, [queryProp])

  return (
    <th
      style={{
        width: column.width || columnWidth || `12%`,
        cursor: column.sortable ? 'pointer' : 'default',
        whiteSpace: column.sortable ? 'nowrap' : 'normal',
      }}
    >
      {!search ? (
        <div
          className="flex w-full justify-between items-center px-[8px] pt-[8px] pb-[9px]"
          onClick={() =>
            column.accessor && column.sortable
              ? handleSortBy?.(column.accessor)
              : null
          }
        >
          <span className="grow text-start">{column.title}</span>
          {column.searchable && (
            <button
              className='px-[12px] py-[6px]'
              onClick={(e: any) => {
                e.stopPropagation()
                setSearch(true)
                setTimeout(() => {
                  ref.current?.focus()
                })
              }}
            >
              <Icon icon="search"
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
                fill="#ADB5BD" />
            </button>
          )}
        </div>
      ) : (
        <div
          className="flex w-full justify-between items-center"
          style={{
            minWidth: '200px',
          }}
        >
          <div className='flex w-full rounded-[4px] '>
            <input
              type="text"
              className='w-full'
              value={query || ''}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => onChange(e.target.value)}
              placeholder="Search"
              ref={ref}
            />
            <button
              className='px-[12px] py-[6px]'
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={(e: any) => {
                e.stopPropagation()
                onChange(null)
                setSearch(false)
              }}
            >
              <Icon icon="close"
                width="16px"
                height="16px"
                viewBox="0 0 16 16" />
            </button>
          </div>
        </div>
      )}
    </th>
  )
}

export const Table: FC<ITable> = ({
  columns,
  data,
  isCheck,
  handleSortBy,
  columnWidth,
  handleSearch,
  loading,
  noDataMessage,
  searchParams,
  alignment = 'top',
}) => {
  return (
    <table>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <ThElement
              columnWidth={columnWidth}
              key={`head-${index}`}
              handleSortBy={handleSortBy}
              column={column}
              handleSearch={handleSearch}
              query={searchParams?.[column.accessor]}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={`body-row-${index}`}>
            {columns.map((column, tdIndex) => (
              <td
                key={`td-${index}-${tdIndex}`}
                style={{
                  background: `${isCheck?.includes(row.id) ? '#E2D9F3' : ''}`,
                  verticalAlign: alignment,
                  ...column.style?.(row),
                }}
                className={`py-[10px] items-start self-stretch`}
              >
                {column.render
                  ? column.render(row, index)
                  : row[column.accessor]}
              </td>
            ))}
          </tr>
        ))}
        {data.length === 0 && !loading && (
          <NoDataComponent message={noDataMessage} colSpan={columns.length} />
        )}
      </tbody>
    </table>
  )
}
