import { useDraggable } from "@dnd-kit/core";
import { useEffect, useId, useRef, useState } from "react";
import { CircleSeat } from "./CircleSeat";
import { Dropdown } from "../ui/dropdown";

interface CircleAreaProps extends React.HTMLAttributes<HTMLDivElement> {
  numSeats: number;
  areaId?: string;
  handleDragEnd?: ({ x, y }: { x: number; y: number }) => void;
  scale?: number;
  initialRadius?: number;
}

export const CircleArea: React.FC<CircleAreaProps> = ({
  numSeats,
  style,
  areaId,
  handleDragEnd,
  scale = 1,
  initialRadius = 100,
  ...rest
}) => {
  const [editMode, setEditMode] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [radius, setRadius] = useState(initialRadius);
  const [isDragging, setIsDragging] = useState(false);
  const id = useId();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `draggable-${id}`,
    data: { id: areaId, ref },
  });

  const dragStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0) scale(${scale})`,
      }
    : undefined;

  const circleSize = Math.max(20, radius * 0.25);
  const centerSize = Math.max(60, radius * 1.4);

  const angleStep = (2 * Math.PI) / numSeats; // Angle between each seat

  const seats = [];

  for (let i = 0; i < numSeats; i++) {
    const angle = i * angleStep;
    seats.push({
      angle,
      radius,
      size: circleSize,
    });
  }

  const handleResize = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!editMode) return;

    const startResize = (e: PointerEvent) => {
      const startX = e.clientX;
      const startY = e.clientY;
      const startRadius = radius;
      const circle = ref?.getBoundingClientRect();
      const centerX = circle ? circle.left + circle.width / 2 : startX;
      const centerY = circle ? circle.top + circle.height / 2 : startY;

      const onMouseMove = (e: PointerEvent) => {
        const dx = e.clientX - centerX;
        const dy = e.clientY - centerY;
        const newRadius = Math.sqrt(dx * dx + dy * dy);
        setRadius(Math.max(50, newRadius)); // Minimum radius of 50px
      };

      const onMouseUp = () => {
        document.removeEventListener("pointermove", onMouseMove);
        document.removeEventListener("pointerup", onMouseUp);
        setIsDragging(false);
      };

      setIsDragging(true);
      document.addEventListener("pointermove", onMouseMove);
      document.addEventListener("pointerup", onMouseUp);
    };

    startResize(e.nativeEvent as unknown as PointerEvent);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref &&
        !ref.contains(event.target as Node) &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown, ref]);

  return (
    <>
      <div
        style={{
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
          position: "relative",
          ...style,
          ...dragStyle,
        }}
        className="absolute z-50"
        ref={(node) => {
          setNodeRef(node);
          setRef(node);
        }}
        {...(!editMode
          ? {
              onClick: () => {
                setShowDropdown(true);
              },
            }
          : {
              ...rest,
              ...listeners,
              ...attributes,
            })}
      >
        {seats.map((seat) => (
          <CircleSeat
            key={seat.angle}
            {...seat}
            onClick={() => {
              console.log(seat);
            }}
          />
        ))}
        <div
          style={{
            width: `${centerSize}px`,
            height: `${centerSize}px`,
            border: "2px solid black",
            borderRadius: "50%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        {editMode && (
          <>
            {/* Resize handle (blue dot) */}
            <div
              className="absolute right-0 bottom-0 w-4 h-4 bg-blue-500 rounded-full cursor-se-resize z-50"
              style={{
                transform: "translate(50%, 50%)",
                opacity: isDragging ? 0.8 : 0.5,
              }}
              onMouseDown={handleResize}
            />
            {/* Cancel edit mode button (red dot) */}
            <div
              className="absolute left-0 bottom-0 w-4 h-4 bg-red-500 rounded-full cursor-pointer z-50 hover:opacity-80"
              style={{
                transform: "translate(-50%, 50%)",
                opacity: 0.5,
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEditMode(false);
                setShowDropdown(false);
              }}
            />
          </>
        )}
      </div>
      {showDropdown && (
        <Dropdown
          ref={dropdownRef}
          items={[
            {
              label: "Edit Seats",
              onClick: () => {
                setShowDropdown(false);
                setEditMode(true);
              },
            },
            { label: "Edit Area", onClick: () => {} },
            { label: "Duplicate Area", onClick: () => {} },
            { label: "Delete Area", onClick: () => {} },
          ]}
          style={{
            top: ref?.getBoundingClientRect().top,
            left: ref?.getBoundingClientRect().left || 0 + radius * 2,
          }}
        />
      )}
    </>
  );
};
