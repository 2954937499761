import { FC, forwardRef } from "react";

type Props = {
  style?: React.CSSProperties;
  items: {
    label: string;
    onClick: () => void;
  }[];
};

const DropdownItem: FC<{ children: React.ReactNode; onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <li className="w-full hover:bg-gray-100 text-start">
      <button
        className="w-full p-2 hover:bg-gray-100 text-start text-sm"
        onClick={onClick}
      >
        {children}
      </button>
    </li>
  );
};

export const Dropdown = forwardRef<HTMLDivElement, Props>(
  ({ style, items }, ref) => {
    return (
      <div
        ref={ref}
        className="absolute bg-white shadow-lg"
        style={{
          top: style?.top ?? 0,
          left: style?.left ?? 0,
          zIndex: 100,
          width: "200px",
        }}
      >
        <ul className="flex flex-col py-2">
          {items.map((item) => (
            <DropdownItem key={item.label} onClick={item.onClick}>
              {item.label}
            </DropdownItem>
          ))}
        </ul>
      </div>
    );
  }
);

Dropdown.displayName = "Dropdown";
