import React, { forwardRef } from "react";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, checked, onChange, required = false, error, ...rest }, ref) => {
    return (
      <div className="flex flex-col">
        <div className="flex items-center mb-2">
          <input
            id={label}
            type="checkbox"
            ref={ref}
            checked={checked}
            onChange={onChange}
            required={required}
            className="h-4 w-4 text-primary-700 accent-primary-700 border border-gray-300 rounded focus:outline-none"
            {...rest}
          />
          {label && (
            <label
              htmlFor={label}
              className="ml-2 capitalize text-gray-700 text-sm font-medium"
            >
              {label}
            </label>
          )}
        </div>
        {error && <span className="text-red-500 text-xs italic">{error}</span>}
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
