import { useEffect, useState } from "react"
import { capFirstLetter } from "../components/AddNewLocationModal"
import { Country, Languages, Locations } from "../models"
import { yupResolver } from "@hookform/resolvers/yup"
import { useFieldArray, useForm } from "react-hook-form"
import * as yup from 'yup'
import { DeleteConfirmationModal } from "../components/DeleteConfirmationModal"
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps"

interface IAddEventValues {
  names: {
    language: string
    name: string
    slug: string
    metaTitle: string
    description: string
  }[]
  healthTerms: {
    language: string
    description: string
  }[]
  minimumAge: number
  address: string
  city: string
  country: string
  availableCountries: string
  seatSelectionEnable: boolean
  selectedLocation?: string | null
  selectedVersion?: string | null
  latitude: number
  longitude: number
  startDate: number
  endDate: number
  instalmentsEndDate: number
  previewVideoURL: string
  mainExperience: string
  tags: string
  sponsored: boolean
  testEnv: boolean
}

const key = "AIzaSyANOaRCVNlH_mS6JBQ8ZKtS1pRnl4jIFA8"
const defaultCenter = {
  lat: 46.77072615394603,
  lng: 23.59711840081874,
}

export const AddEventPage = () => {
  const [allCountries, setAllCountries] = useState<Country[]>([])
  const [allLanguages, setAllLanguages] = useState<Languages[]>([])
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)
  const [allLocations, setAllLocations] = useState<Locations[]>([])
  const [removeLanguage, setRemoveLanguage] = useState<number>()
  const [allExperiences, setAllExperiences] = useState<{ id: string, name: string }[]>([])
  const [marker, setMarker] = useState<{ lat: number, lng: number }>()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deleteText, setDeleteText] = useState<string>('')

  useEffect(() => {
    // get All countries
    setAllCountries([{ id: '1', name: 'Romania', locale: 'ro' }, { id: '2', name: 'USA', locale: 'en' }])
    // get all experiences
    setAllExperiences([{ id: '1', name: 'exp 1' }, { id: '2', name: 'exp 2' }])
    // get all possible Languages
    setAllLanguages([{ id: '1', locale: 'en', name: 'English' }, { id: '2', locale: 'ro', name: 'Romanian' }])
  }, [])

  useEffect(() => {
    if (selectedCountry !== null) {
      // get all locations in a country
      setAllLocations([
        {
          id: '1',
          name: 'Sala cu Orgă',
          country: '1',
          city: '1',
          versions: []
        },
        {
          id: '2',
          name: 'Sala Polivalentă',
          country: '1',
          city: '2',
          versions: [
            {
              id: '2-1',
              name: 'Sala Polivalentă (UNTOLD)',
            },
            {
              id: '2-2',
              name: 'Sala Polivalentă (EXTASY EVENT)',
            },
          ]
        },
      ])
    }
  }, [selectedCountry])

  const schema = yup.object().shape({
    names: yup
      .array()
      .of(
        yup.object().shape({
          language: yup.string().nullable().required('Field is required'),
          name: yup.string().required('Field is required'),
          slug: yup.string().required('Field is required'),
          metaTitle: yup.string().required('Field is required'),
          description: yup.string().required('Field is required'),
        }),
      ).required(),
    healthTerms: yup
      .array()
      .of(
        yup.object().shape({
          language: yup.string().nullable().required('Field is required'),
          description: yup.string().required('Field is required'),
        }),
      ).required(),

    minimumAge: yup.number().required('Field is required'),
    address: yup.string().required('Field is required'),
    city: yup.string().required('Field is required'),
    country: yup.string().required('Field is required'),
    availableCountries: yup.string().required('Field is required'),
    seatSelectionEnable: yup.boolean().required('Field is Required'),
    selectedLocation: yup.string().when('seatSelectionEnable', {
      is: (val: boolean) => (val ? true : false),
      then: (schema) => schema.required('Field is required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    selectedVersion: yup.string().when('seatSelectionEnable', {
      is: (val: boolean) => (val ? true : false),
      then: (schema) => schema.required('Field is required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    latitude: yup.number().required('Field is required'),
    longitude: yup.number().required('Field is required'),
    startDate: yup.number().required('Field is required'),
    endDate: yup.number().required('Field is required'),
    instalmentsEndDate: yup.number().required('Field is required'),
    previewVideoURL: yup.string().required('Field is required'),
    mainExperience: yup.string().required('Field is required'),
    tags: yup.string().required('Field is required'),
    sponsored: yup.boolean().required('Field is required'),
    testEnv: yup.boolean().required('Field is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm<IAddEventValues>({
    mode: 'onSubmit',
    resolver: yupResolver<IAddEventValues>(schema),
    defaultValues: {
      country: '',
      city: ''
    }
  })

  const { fields: namesFields, append: namesAppend, remove: namesRemove } = useFieldArray({
    control,
    name: 'names',
  })

  const { fields: healthTermsFields, append: healthTermsAppend, remove: healthTermsRemove } = useFieldArray({
    control,
    name: 'healthTerms',
  })

  const handleDelete = () => {
    if (deleteText === 'Names & Descriptions') {
      // remove the language via api or if there is no remove call then remove the language then submit update
      namesRemove(removeLanguage)
    } else {
      healthTermsRemove(removeLanguage)
    }
  }

  const submit = (values: IAddEventValues) => {
    console.log(values)
    reset()
  }

  const selectedLocationWatch = watch('selectedLocation')
  const seatSelectionEnableWatch = watch('seatSelectionEnable')

  const onMapClick = (e: any) => {
    setMarker({
      lat: e.detail.latLng.lat,
      lng: e.detail.latLng.lng
    })
    setValue('latitude', e.detail.latLng.lat)
    setValue('longitude', e.detail.latLng.lng)
  }

  return (
    <div className="bg-[#F8F9FC] p-[20px]"> {/* TODO remove this, this is just to se borders of the component */}
      <div className=" bg-[#fff] border border-[#000] border-opacity-20 rounded">
        <form id="add-event-form" onSubmit={handleSubmit(submit)} >
          <div className="flex flex-col gap-[16px] p-[16px]">
            <div className='flex flex-col w-full gap-[10px]'>
              <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Names & Descriptions</span>
              {namesFields.map((field, index) => {
                return (
                  <div key={field.id} className="flex flex-col w-full bg-[#F8F9FA] p-[20px] gap-[16px] rounded-[5px]" >
                    <div className="flex flex-col gap-[10px] w-full">
                      <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Language</span>
                      <select {...register(`names.${index}.language`)}
                        defaultValue={''}
                        className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                      >
                        <option disabled value=''>Select Language</option>
                        {allLanguages.map((lang) => (
                          <option key={lang.id} value={lang.locale}>
                            {lang.name}
                          </option>
                        ))}
                      </select>
                      {!!(errors.names?.[index]?.language?.message) && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.names?.[index]?.language?.message)}</span>}
                    </div>
                    <div className="flex flex-col gap-[10px] w-full">
                      <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Name</span>
                      <input type='text' {...register(`names.${index}.name`)} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Name' />
                      {!!errors.names?.[index]?.name?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.names?.[index]?.name?.message)}</span>}
                    </div>
                    <div className="flex flex-col gap-[10px] w-full">
                      <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Slug</span>
                      <input type='text' {...register(`names.${index}.slug`)} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Slug' />
                      {!!errors.names?.[index]?.slug?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.names?.[index]?.slug?.message)}</span>}
                    </div>
                    <div className="flex flex-col gap-[10px] w-full">
                      <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Meta Title (max 70 characters)</span>
                      <input type='text' {...register(`names.${index}.metaTitle`)} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Meta Title' />
                      {!!errors.names?.[index]?.metaTitle?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.names?.[index]?.metaTitle?.message)}</span>}
                    </div>
                    <div className="flex flex-col gap-[10px] w-full">
                      <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Meta Title (max 70 characters)</span>
                      <textarea rows={3} {...register(`names.${index}.description`)} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter description' />
                      {!!errors.names?.[index]?.description?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.names?.[index]?.description?.message)}</span>}
                    </div>
                    <div className="w-full flex justify-end">
                      <button
                        type="button"
                        onClick={() => {
                          setShowDeleteModal(true)
                          setRemoveLanguage(index)
                          setDeleteText('Names & Descriptions')
                        }}
                        className="bg-[#DC3545] rounded text-[#FFF] px-[12px] py-[6px] border border-[#DC3545] w-[73px]"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )
              })}
              <button
                type="button"
                className="px-[12px] py-[6px] bg-[#6F42C1] border border-[#6F42C1] w-[98px] rounded text-[#fff]"
                onClick={() =>
                  namesAppend({
                    language: '',
                    name: '',
                    slug: '',
                    metaTitle: '',
                    description: '',
                  })
                }
              >
                Add More
              </button>
            </div>
            <div className="h-[0px] w-full border-b border-b-[#E9ECEF]" />
            <div className='flex flex-col w-full gap-[10px]'>
              <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Health Terms</span>
              {healthTermsFields.map((field, index) => {
                return (
                  <div key={field.id} className="flex flex-col w-full bg-[#F8F9FA] p-[20px] gap-[16px] rounded-[5px]" >
                    <div className="flex flex-col gap-[10px] w-full">
                      <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Language</span>
                      <select {...register(`healthTerms.${index}.language`)}
                        defaultValue={''}
                        className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                      >
                        <option disabled value=''>Select Language</option>
                        {allLanguages.map((lang) => (
                          <option key={lang.id} value={lang.locale}>
                            {lang.name}
                          </option>
                        ))}
                      </select>
                      {!!errors.healthTerms?.[index]?.language?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.healthTerms?.[index]?.language?.message)}</span>}
                    </div>
                    <div className="flex flex-col gap-[10px] w-full">
                      <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Meta Title (max 70 characters)</span>
                      <textarea rows={3} {...register(`healthTerms.${index}.description`)} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter description' />
                      {!!errors.healthTerms?.[index]?.description?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.healthTerms?.[index]?.description?.message)}</span>}
                    </div>
                    <div className="w-full flex justify-end">
                      <button
                        type="button"
                        onClick={() => {
                          setShowDeleteModal(true)
                          setRemoveLanguage(index)
                          setDeleteText('Health Terms')
                        }}
                        className="bg-[#DC3545] rounded text-[#FFF] px-[12px] py-[6px] border border-[#DC3545] w-[73px]"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )
              })}
              <button
                type="button"
                className="px-[12px] py-[6px] bg-[#6F42C1] border border-[#6F42C1] w-[98px] rounded text-[#fff]"
                onClick={() =>
                  healthTermsAppend({
                    language: '',
                    description: '',
                  })
                }
              >
                Add More
              </button>
            </div>
            <div className="h-[0px] w-full border-b border-b-[#E9ECEF]" />
            <div className='flex flex-col w-full gap-[10px]'>
              <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Minimum Age</span>
              <input type='number' {...register('minimumAge')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Minimum Age' />
              {!!errors.minimumAge?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Field is required</span>}
            </div>
            <div className='flex flex-col w-full gap-[10px]'>
              <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Address</span>
              <input type='text' {...register('address')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Address' />
              {!!errors.address?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.address?.message)}</span>}
            </div>
            <div className='flex gap-[16px]'>
              <div className='flex flex-col w-full gap-[10px]'>
                <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>City</span>
                <input type='text' {...register('city')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder="Enter City" />
                {!!errors.city?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Field is required</span>}
              </div>
              <div className='flex flex-col w-full gap-[10px]'>
                <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Country</span>
                <select className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                  {...register('country')}
                  defaultValue={''}
                >
                  <option disabled value={''}>Select Country</option>
                  {
                    allCountries.map(country => {
                      return (
                        <option key={country.id} value={country.id}>{country.name}</option>
                      )
                    })
                  }
                </select>
                {!!errors.country?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.country?.message)}</span>}
              </div>
              <div className='flex flex-col w-full gap-[10px]'>
                <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Available Countries</span>
                <select className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                  {...register('availableCountries')}
                  defaultValue={''}
                >
                  <option value={''}>Nothing Selected</option>
                  {
                    allCountries.map(country => {
                      return (
                        <option key={country.id} value={country.id}>{country.name}</option>
                      )
                    })
                  }
                </select>
                {!!errors.availableCountries?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.availableCountries?.message)}</span>}
              </div>
            </div>
            <div className="flex gap-[8px]">
              <input
                type="checkbox"
                style={{ cursor: 'pointer' }}
                {...register('seatSelectionEnable')}
              />
              <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#212529]'>Enable Seat Selection</span>
            </div>
            {seatSelectionEnableWatch && (
              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col gap-[10px] w-full">
                  <span className='text-[16px] font-[700] leading-[24px] text-helvetica'>Select Location</span>
                  <select className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                    {...register('selectedLocation')}
                    defaultValue={''}
                  >
                    <option disabled value={''}>Select Location</option>
                    {
                      allLocations.map(location => {
                        return (
                          <option key={location.id} value={location.id}>{location.name}</option>
                        )
                      })
                    }
                  </select>
                  {!!errors.selectedLocation?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.selectedLocation?.message)}</span>}
                </div>
                <div className="flex flex-col gap-[10px] w-full">
                  <span className='text-[16px] font-[700] leading-[24px] text-helvetica'>Version</span>
                  <select className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                    disabled={selectedLocationWatch === null}
                    {...register('selectedVersion')}
                    defaultValue={''}
                  >
                    <option disabled value={''}>Select Version</option>
                    {
                      allLocations.map(location => {
                        return (
                          <option key={location.id} value={location.id}>{location.name}</option>
                        )
                      })
                    }
                  </select>
                  {!!errors.selectedVersion?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.selectedVersion?.message)}</span>}
                </div>
              </div>
            )}
            <div className="w-full flex justify-end">
              <button
                type="button"
                className="px-[12px] py-[6px] bg-[#6F42C1] border border-[#6F42C1] w-[216px] rounded text-[#fff]"
                onClick={() => {
                  // insert api call to get coordinate for marker and replace wiht results
                  setValue('latitude', 46.77387768945304)
                  setValue('longitude', 23.599469661712646)
                  setMarker({ lat: 46.77387768945304, lng: 23.599469661712646 })
                }}
              >
                Move Map Pin to Address
              </button>
            </div>
            {/* <Wrapper apiKey={key}>
              <Map center={defaultCenter} zoom={15} locations={markers}>
                {markers.map((marker) => (
                  <Marker
                    position={{
                      lat: marker.lat,
                      lng: marker.lng
                    }} 
                    onClick={()=>console.log('marker')}
                    />
                ))}
              </Map>
            </Wrapper> */}

            <APIProvider apiKey={key}>
              <Map
                style={{ height: '484px', width: '100%' }}
                defaultCenter={defaultCenter}
                defaultZoom={15}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                onClick={onMapClick}
              >
                {marker && <Marker
                  position={{
                    lat: marker.lat,
                    lng: marker.lng
                  }} />}
              </Map>
            </APIProvider>

            <div className='flex gap-[16px]'>
              <div className='flex flex-col w-full gap-[10px]'>
                <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Latitude</span>
                <input type='number' {...register('latitude')} step="any" className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Latitude' />
                {!!errors.latitude?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Field is required</span>}
              </div>
              <div className='flex flex-col w-full gap-[10px]'>
                <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Longitude</span>
                <input type='number' step="any" {...register('longitude')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Longitude' />
                {!!errors.longitude?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Field is required</span>}
              </div>
            </div>

            <div className='flex gap-[16px]'>
              <div className='flex flex-col w-full gap-[10px]'>
                <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Start Date</span>
                <input type='date' {...register('startDate')} className='purple-datepicker rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px] relative' placeholder='Select Date' />
                {!!errors.startDate?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Field is required</span>}
              </div>
              <div className='flex flex-col w-full gap-[10px]'>
                <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>End Date</span>
                <input type='date' {...register('endDate')} className='purple-datepicker rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px] relative' placeholder='Select Date' />
                {!!errors.endDate?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Field is required</span>}
              </div>
            </div>
            <div className='flex flex-col w-full gap-[10px]'>
              <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Installments End Date</span>
              <input type='date' {...register('instalmentsEndDate')} className='purple-datepicker rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px] relative' placeholder='Select Date' />
              {!!errors.instalmentsEndDate?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Field is required</span>}
            </div>
            <div className='flex flex-col w-full gap-[10px]'>
              <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Preview Video URL</span>
              <input type='text' {...register('previewVideoURL')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter URL' />
              {!!errors.previewVideoURL?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.previewVideoURL?.message)}</span>}
            </div>
            <div className='flex flex-col w-full gap-[10px]'>
              <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Main Experience</span>
              <select className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                {...register('mainExperience')}
                defaultValue={''}
              >
                <option disabled value={''}>Select Experience</option>
                {
                  allExperiences.map(experience => {
                    return (
                      <option key={experience.id} value={experience.id}>{experience.name}</option>
                    )
                  })
                }
              </select>
              {!!errors.mainExperience?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Field is required</span>}
            </div>
            <div className='flex flex-col w-full gap-[10px]'>
              <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Tags</span>
              <input type='text' {...register('tags')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter multiple tags separated by a comma (,)' />
              {!!errors.tags?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.tags?.message)}</span>}
            </div>
            <div className="flex gap-[8px]">
              <input
                type="checkbox"
                style={{ cursor: 'pointer' }}
                {...register('sponsored')}
              />
              <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#212529]'>Sponsored</span>
            </div>
            <div className="flex gap-[8px]">
              <input
                type="checkbox"
                style={{ cursor: 'pointer' }}
                {...register('testEnv')}
              />
              <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#212529]'>Test Event</span>
            </div>
          </div>
        </form>
        <div className="p-[12px] border-t border-t-[#DEE2E6] w-full">
          <button
            className="text-[16px] font-[400] rounded leading-[24px] text-[#fff] text-helvetica bg-[#6F42C1] border border-[#6F42C1] px-[12px] py-[6px] w-full"
            form="add-event-form"
            type="submit"
          >
            Add Event
          </button>
        </div>
      </div >
      <DeleteConfirmationModal
        showDeleteConfirmationModal={showDeleteModal}
        setDeleteConfirmationModal={setShowDeleteModal}
        deleteTitle={deleteText}
        deleteFunc={handleDelete}
      />
    </div >
  )
}