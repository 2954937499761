import { useDraggable } from "@dnd-kit/core";
import { SquareSeat } from "./SquareSeat";
import { useId, useState, useRef, useEffect } from "react";
import { Dropdown } from "../ui/dropdown";

interface SquareAreaProps extends React.HTMLAttributes<HTMLDivElement> {
  numSeatsPerSide: number;
  squareSide?: number;
  areaId?: string;
  handleDragEnd?: ({ x, y }: { x: number; y: number }) => void;
  scale?: number;
  initialSquareSide?: number;
}

export const SquareArea: React.FC<SquareAreaProps> = ({
  numSeatsPerSide,
  squareSide: propSquareSide = 150,
  style,
  areaId,
  handleDragEnd,
  scale = 1,
  ...rest
}) => {
  const [editMode, setEditMode] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [squareSide, setSquareSide] = useState(propSquareSide);
  const [isDragging, setIsDragging] = useState(false);
  const id = useId();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `draggable-${id}`,
    data: { id: areaId, ref },
  });
  const dragStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0) scale(${scale})`,
      }
    : undefined;

  const totalSeats = numSeatsPerSide * 4;

  const seatSize = Math.min(30, 300 / totalSeats);
  const spaceBetweenSeats =
    (squareSide - seatSize * numSeatsPerSide) / (numSeatsPerSide - 1);
  const offset = seatSize + 10;

  const seats = [];

  // Generate seats for the top side
  for (let i = 0; i < numSeatsPerSide; i++) {
    const x = i * (seatSize + spaceBetweenSeats);
    const y = -offset;
    seats.push(
      <SquareSeat key={`top-${i}`} top={y} left={x} size={seatSize} />
    );
  }

  // Generate seats for the bottom side
  for (let i = 0; i < numSeatsPerSide; i++) {
    const x = i * (seatSize + spaceBetweenSeats);
    const y = -offset;
    seats.push(
      <SquareSeat key={`bottom-${i}`} bottom={y} left={x} size={seatSize} />
    );
  }

  // Generate seats for the left side
  for (let i = 0; i < numSeatsPerSide; i++) {
    const x = -offset;
    const y = i * (seatSize + spaceBetweenSeats);
    seats.push(
      <SquareSeat key={`left-${i}`} left={x} top={y} size={seatSize} />
    );
  }

  // Generate seats for the right side
  for (let i = 0; i < numSeatsPerSide; i++) {
    const x = -offset;
    const y = i * (seatSize + spaceBetweenSeats);
    seats.push(
      <SquareSeat key={`right-${i}`} right={x} top={y} size={seatSize} />
    );
  }

  const handleResize = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!editMode) return;

    const startResize = (e: PointerEvent) => {
      const startX = e.clientX;
      const startY = e.clientY;
      const startSize = squareSide;

      const onMouseMove = (e: PointerEvent) => {
        const dx = e.clientX - startX;
        const dy = e.clientY - startY;
        const delta = Math.max(dx, dy);
        const newSize = Math.max(50, startSize + delta); // Minimum size of 50px
        setSquareSide(newSize);
      };

      const onMouseUp = () => {
        document.removeEventListener("pointermove", onMouseMove);
        document.removeEventListener("pointerup", onMouseUp);
        setIsDragging(false);
      };

      setIsDragging(true);
      document.addEventListener("pointermove", onMouseMove);
      document.addEventListener("pointerup", onMouseUp);
    };

    startResize(e.nativeEvent as unknown as PointerEvent);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref &&
        !ref.contains(event.target as Node) &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown, ref]);

  return (
    <>
      <div
        style={{
          ...style,
          ...dragStyle,
        }}
        className="absolute z-50"
        ref={(node) => {
          setNodeRef(node);
          setRef(node);
        }}
        {...(!editMode
          ? {
              onClick: () => {
                setShowDropdown(true);
              },
            }
          : {
              ...listeners,
              ...attributes,
            })}
      >
        <div
          className="relative border-2 border-black"
          style={{
            width: `${squareSide}px`,
            height: `${squareSide}px`,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {seats}
          {editMode && (
            <>
              <div
                className="absolute right-0 bottom-0 w-4 h-4 bg-blue-500 rounded-full cursor-se-resize z-50"
                style={{
                  transform: "translate(50%, 50%)",
                  opacity: isDragging ? 0.8 : 0.5,
                }}
                onMouseDown={handleResize}
              />
              <div
                className="absolute left-0 bottom-0 w-4 h-4 bg-red-500 rounded-full cursor-pointer z-50 hover:opacity-80"
                style={{
                  transform: "translate(-50%, 50%)",
                  opacity: 0.5,
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setEditMode(false);
                  setShowDropdown(false);
                }}
              />
            </>
          )}
        </div>
      </div>
      {showDropdown && (
        <Dropdown
          ref={dropdownRef}
          items={[
            {
              label: "Edit Seats",
              onClick: () => {
                setShowDropdown(false);
                setEditMode(true);
              },
            },
            { label: "Edit Area", onClick: () => {} },
            { label: "Duplicate Area", onClick: () => {} },
            { label: "Delete Area", onClick: () => {} },
          ]}
          style={{
            top: ref?.getBoundingClientRect()?.top,
            left: ref?.getBoundingClientRect()?.left || 0 + squareSide,
          }}
        />
      )}
    </>
  );
};
