import { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MenuItems } from '../constants/menu'
import { Icon } from './Icon'

interface SidebarProps {
}

const Sidebar: FC<SidebarProps> = () => {
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useState<boolean[]>([])

  useEffect(() => {
    let temp: boolean[] = []
    MenuItems.map((item) => temp.push(false))
    setCollapsed(temp)
  }, [])

  return (
    <div className="sidebar px-[21px] py-[20px] min-w-[306px] min-h-[100vh] flex flex-col gap-[20px]">
      <div className="py-[5px]">
        <Link to="/">
          <img src={require("../assets/ExtasyLogo.png")} alt={'logo'} className='' />
        </Link>
      </div>
      <div className="h-[0px] w-full border-b border-b-[#FFF]/[.2]" />

      <div
        className="flex flex-col gap-[5px]"
      >
        {MenuItems.map((item, index) => {
          return (
            <>
              <div className={`flex gap-[8px] px-[16px] py-[8px] items-center justify-start ${pathname === item.path ? 'rounded-[10px] bg-[#fff]/[.1]' : ''} cursor-pointer`}
                onClick={(e) => {
                  if (item.children && item.children?.length > 0) {
                    const temp = [...collapsed]
                    temp.fill(false)
                    temp[index] = !collapsed[index]
                    setCollapsed(temp)
                    e.preventDefault()
                  }
                }}
              >
                {item.children && item.children?.length > 0 ? (
                  <>
                    <div className='flex items-center content-between w-full gap-[8px]'>
                      {item.icon}
                      <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#fff]'>{item.title}</span>
                    </div>
                    {collapsed[index] ? (
                      <Icon icon="chevron-up"
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                        fill="#ADB5BD" />
                    ) : (
                      <Icon icon="chevron-right"
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                        fill="#ADB5BD" />
                    )}
                  </>
                ) : (
                  <Link className='flex items-center content-between w-full gap-[8px]' to={item.path}>
                    {item.icon}
                    <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#fff]'>{item.title}</span>
                  </Link>
                )}
              </div >
              {item.children && item.children?.length > 0 && collapsed[index] && (
                item.children.map((it) => {
                  return (
                    <Link to={it.path}>
                      <div className={`flex gap-[8px] pl-[40px] pr-[16px] py-[8px] items-center justify-start ${pathname === it.path ? 'rounded-[10px] bg-[#fff]/[.2]' : ''}`}>
                        <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#fff]'>{it.title}</span>
                      </div>
                    </Link>
                  )
                })
              )}
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Sidebar
