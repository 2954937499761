import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { capFirstLetter } from './AddNewLocationModal'
import { ChangeEvent, useEffect, useState } from 'react'
import { Icon } from './Icon'
import { Version } from '../models'

export interface AddNewVersionModalProps {
  showAddNewVersion: boolean
  setShowNewVersion: (show: boolean) => void
  versionParentId: string
  version: Version | null
}

interface IAddVersionValues {
  name: string
  description: string
  map?: string | null
  width: number
  height: number
}

export const AddNewVersionModal = (
  props: AddNewVersionModalProps,
): JSX.Element => {
  const {
    showAddNewVersion,
    setShowNewVersion,
    version,
    versionParentId,
  } = props

  const [image, setImage] = useState('')
  const [imageName, setImageName] = useState('')

  useEffect(() => {
    if (version) {
      setValue('name', version.name)
      setValue('description', version.description)
      setValue('width', version.width)
      setValue('height', version.height)
      if (!!version.map) {
        setValue('map', version.map)
        setImage(version.map)
        const url = new URL(version.map)
        const i = url?.pathname?.lastIndexOf('/') + 1
        const imageName = url?.pathname?.substring(i)
        setImageName(imageName)
      }
    }
  }, [version])


  const handleClose = () => {
    reset()
    setShowNewVersion(false)
  }

  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    map: yup.string().optional().nullable(),
    width: yup.number().min(1024).required(),
    height: yup.number().min(1024).required(),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IAddVersionValues>({
    mode: 'onChange',
    resolver: yupResolver<IAddVersionValues>(schema),
  })

  const submit = (values: IAddVersionValues) => {

    reset()
    setShowNewVersion(false)
  }

  return (
    <div className={`fixed left-0 top-0 bg-black bg-opacity-50 w-screen h-screen justify-center items-center ${showAddNewVersion ? 'flex' : 'hidden'}`} onClick={handleClose}>
      <div className="bg-white rounded shadow-md w-[798px]" onClick={(e) => { e.stopPropagation() }}>
        <div className="flex flex-col">
          <div className="border-b border-b-[#dee2e6] p-[16px] ">
            <span className="text-[20px] font-[500] leading-[24px] text-helvetica text-[#212529] tracking-[0.2px]">
              Add New Version
            </span>
          </div>
          <div className="border-b border-b-[#dee2e6] p-[16px] ">
            <form id="add-version-form" onSubmit={handleSubmit(submit)}>
              <div className='flex flex-col w-full gap-[16px]'>
                <div className='flex flex-col w-full gap-[10px]'>
                  <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Name</span>
                  <input type='text' {...register('name')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Venue Name' />
                  {!!errors.name?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.name?.message)}</span>}
                </div>
                <div className='flex flex-col w-full gap-[10px]'>
                  <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Description</span>
                  <input type='text' {...register('description')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Description' />
                  {!!errors.description?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.description?.message)}</span>}
                </div>
                <div className='flex gap-[16px]'>
                  <div className='flex flex-col w-full gap-[10px]'>
                    <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Width</span>
                    <input type='number' step="any" min={1024} {...register('width')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Canvas Width' />
                    {!!errors.width?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.width?.message)}</span>}
                  </div>
                  <div className='flex flex-col w-full gap-[10px]'>
                    <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Height</span>
                    <input type='number' step="any" min={1024} {...register('height')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Canvas Height' />
                    {!!errors.height?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.height?.message)}</span>}
                  </div>
                </div>
                <div className='flex w-full gap-[10px] items-center h-[66px]'>
                  <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529] w-[130px]'>Upload Map (min 1024x1024)</span>
                  {image !== '' ? <div className='px-[8px] py-[9px] flex gap-[8px] border border-[#CED4DA] h-[66px] w-full rounded-[2px] items-center'>
                    <img src={image} alt={imageName} className='w-[48px] h-[48px] border-2 border-[#A98EDA] rounded-[2px]' />
                    <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#6F42C1] w-full'>{imageName}</span>
                    <Icon icon='trash' width='14' height='14' viewBox="0 0 14 14" />
                  </div> :
                    <input type='file' multiple={false} accept="image/png" onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                      const file = e?.target?.files?.[0]
                      if (file) {
                        const data = { photoUrl: 'https://oceana.org/wp-content/uploads/sites/18/harp_seal_pup_shutterstock_142780513_credited-scaled.jpg', photoKey: 'asdfg' }//uploadImage(file)
                        if (data.photoUrl) {
                          setImage(data.photoUrl)
                          setImageName(file.name)
                          setValue('map', data.photoUrl, {
                            shouldValidate: true,
                            shouldDirty: true,
                          })
                        }
                      }
                    }
                    } />
                  }
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-between gap-[10px] p-[16px]">
            <button
              onClick={handleClose}
              className="px-[12px] py-[6px] bg-[#DEE2E6] rounded-[4px] border border-[#DEE2E6]"
            >
              Cancel
            </button>
            <button
              className="text-[16px] font-[400] rounded leading-[24px] text-[#fff] text-helvetica bg-[#6F42C1] border border-[#6F42C1] px-[12px] py-[6px]"
              form="add-version-form"
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
