import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { EventVenuesPage } from "./pages/EventVenuesPage";
import { AddEventPage } from "./pages/AddEventPage";
import { SeatsBuilderPage } from "./pages/SeatsBuilder";
import Page from "./pages/Page";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      document.cookie =
        "JSESSIONID=4fGjFTsvsbz1kV4iuaBnnG17ylG3isvyqfyH_uFl; path=/";
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter basename="/v2">
          <Routes>
            <Route path="/" element={<Page />} >
              <Route path="/venues" element={<EventVenuesPage />} />
              <Route path="/add-events" element={<AddEventPage />} />
              <Route path="/seats-builder" element={<SeatsBuilderPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
