import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Icon } from "../Icon";
import Button from "../ui/button";
import Checkbox from "../ui/checkbox";
import Input from "../ui/input";
import Select from "../ui/select";

type Props = {
  toggle: (visible: boolean) => void;
};

type FormValues = {
  name: string;
  showName: boolean;
  positionX: string;
  positionY: string;
  scale: number;
  rotation: number;
  areaType: string;
  color: string;
  columns: string;
  rows: string;
  columnType: string;
  columnNumberStartingAt: string;
  rowType: string;
  rowNamesStartingAt: string;
  startingFrom: string;
  width: string;
  height: string;
};

export const AddArea: FC<Props> = ({ toggle }) => {
  const schema = yup.object().shape({
    name: yup.string().label("name").required(),
    showName: yup.boolean().label("show name").required(),
    positionX: yup.string().label("Position X").required(),
    positionY: yup.string().label("Position Y").required(),
    scale: yup
      .number()
      .min(0.1)
      .required()
      .transform((value) => {
        return isNaN(value) ? undefined : value;
      }),
    rotation: yup
      .number()
      .min(-360)
      .max(360)
      .required()
      .transform((value) => {
        return isNaN(value) ? undefined : value;
      }),
    areaType: yup.string().label("area type").required(),
    color: yup.string().required(),
    columns: yup.string().required(),
    rows: yup.string().required(),
    columnType: yup.string().label("column type").required(),
    columnNumberStartingAt: yup
      .string()
      .label("Column number starting at")
      .required(),
    rowType: yup.string().label("row type").required(),
    rowNamesStartingAt: yup.string().label("Row Names Starting at").required(),
    startingFrom: yup.string().label("starting from").required(),
    width: yup.string().required(),
    height: yup.string().required(),
  });

  const areaTypes = [
    {
      label: "Seating",
      value: "Seating",
    },
    {
      label: "Table Round",
      value: "table-round",
    },
    {
      label: "Table Rectangle",
      value: "table-rectangle",
    },
    {
      label: "General",
      value: "general",
    },
  ];

  const columnTypes = [
    {
      label: "Numeric",
      value: "numeric",
    },
    {
      label: "Alphabetic",
      value: "alphabetic",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver<FormValues>(schema),
  });

  const submit = (values: FormValues) => {
    console.log(values);
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-3xl font-bold">Add Area</h2>
          <Button
            variant="secondary"
            onClick={() => {
              toggle(false);
            }}
          >
            <Icon
              icon="sidebar-arrow"
              width="12px"
              height="20px"
              viewBox="0 0 12 20"
            />
          </Button>
        </div>
        <Input
          {...register("name")}
          error={errors.name?.message}
          label="Name"
          placeholder="Section name"
        />
        <Checkbox
          {...register("showName")}
          error={errors.showName?.message}
          label="Active"
        />
        <div className="flex items-start gap-2">
          <Input
            {...register("positionX")}
            error={errors.positionX?.message}
            label="Position X:"
            placeholder="Position X"
          />
          <Input
            {...register("positionY")}
            error={errors.positionY?.message}
            label="Position Y:"
            placeholder="Position Y"
          />
        </div>
        <Input
          {...register("scale")}
          error={errors.scale?.message}
          label="scale"
          placeholder="scale"
        />
        <Input
          {...register("rotation")}
          error={errors.rotation?.message}
          label="rotation"
          placeholder="rotation"
        />
        <div className="flex items-start gap-2">
          <Input
            {...register("width")}
            error={errors.width?.message}
            label="width"
            placeholder="width"
          />
          <Input
            {...register("height")}
            error={errors.height?.message}
            label="height"
            placeholder="height"
          />
        </div>
        <Select
          defaultValue=""
          label="Area Type"
          placeholder="Select Area Type"
          {...register("areaType")}
          error={errors.areaType?.message}
          options={areaTypes}
        />
        <Input
          {...register("color")}
          error={errors.color?.message}
          label="color"
          className="p-0"
          type="color"
          placeholder="color"
        />
        <div className="flex items-start gap-2">
          <Input
            {...register("columns")}
            error={errors.columns?.message}
            label="columns"
            placeholder="columns"
          />
          <Input
            {...register("rows")}
            error={errors.rows?.message}
            label="rows"
            placeholder="rows"
          />
        </div>
        <Select
          defaultValue=""
          label="Column Type"
          placeholder="Select Column Type"
          {...register("columnType")}
          error={errors.columnType?.message}
          options={columnTypes}
        />
        <Input
          {...register("columnNumberStartingAt")}
          error={errors.columnNumberStartingAt?.message}
          label="Column number starting at"
          placeholder="Column number starting at"
        />
        <Select
          defaultValue=""
          label="Row Type"
          placeholder="Select Row Type"
          {...register("rowType")}
          error={errors.rowType?.message}
          options={columnTypes}
        />
        <Select
          defaultValue=""
          label="Row Names Starting at"
          placeholder="Select Row Names Starting at"
          {...register("rowNamesStartingAt")}
          error={errors.rowNamesStartingAt?.message}
          options={[
            {
              value: "Seating",
              label: "Seating",
            },
          ]}
        />
        <Select
          defaultValue=""
          label="Starting from"
          placeholder="Select starting from"
          {...register("startingFrom")}
          error={errors.startingFrom?.message}
          options={[
            {
              value: "Seating",
              label: "Seating",
            },
          ]}
        />
        <Button
          variant="primary"
          type="submit"
          className="w-full mt-4 flex items-center justify-center gap-2"
        >
          <>
            <Icon
              width="17px"
              height="16px"
              viewBox="0 0 17 16"
              icon="check-circle"
            />
            Save
          </>
        </Button>
      </form>
    </>
  );
};
