import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../ui/button";
import { Modal } from "../ui/modal";
import Input from "../ui/input";

export interface AddNewAreaProps {
  visible: boolean;
  setVisible: (show: boolean) => void;
}

interface IFormValues {
  name: string;
  description: string;
  image: File;
}

export const AddNewArea = (props: AddNewAreaProps): JSX.Element => {
  const { visible, setVisible } = props;
  const handleClose = () => {
    reset();
    setVisible(false);
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    image: yup.mixed<File>().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const submit = (values: IFormValues) => {
    console.log(values);
    setVisible(false);
  };

  return (
    <Modal isOpen={visible} onClose={handleClose} title="Add new Area">
      <form id="add-area-form" onSubmit={handleSubmit(submit)}></form>
      <div>
        <Input
          {...register("name")}
          error={errors.name?.message}
          label="Name"
          placeholder="Venue name"
        />
        <Input
          {...register("description")}
          error={errors.description?.message}
          label="Description"
          placeholder="Venue description"
        />
        <div className="flex items-center gap-4">
          <span>Upload Map (min 1024x1024)</span>
          <Input
            {...register("image")}
            type="file"
            accept="image/*"
            error={errors.image?.message}
          />
        </div>
      </div>
      <div className="flex justify-between mt-4 -mx-4 px-4 border-t border-slate-200 pt-4">
        <Button onClick={handleClose} variant="secondary" type="button">
          Cancel
        </Button>
        <Button form="add-area-form" type="submit">
          Done
        </Button>
      </div>
    </Modal>
  );
};
