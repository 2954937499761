import React, { forwardRef } from "react";

// Extend the built-in Button element props (React.ButtonHTMLAttributes)
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "danger";
  children: JSX.Element | string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      type = "button",
      variant = "primary",
      className = "",
      disabled,
      onClick,
      ...rest
    },
    ref
  ) => {
    const baseClasses =
      "font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2";
    const variantClasses = {
      primary:
        "bg-primary-700 hover:bg-primary-900 text-white focus:ring-primary-900",
      secondary: "bg-gray-700 hover:bg-gray-800 text-white focus:ring-gray-700",
      danger: "bg-red-500 hover:bg-red-700 text-white focus:ring-red-500",
    };

    return (
      <button
        ref={ref}
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`${baseClasses} ${variantClasses[variant]} ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        } ${className}`}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
