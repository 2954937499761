import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { City, Country, Location } from '../models'
import { useEffect, useState } from 'react'


export const capFirstLetter = (str?: string) => {
  if (str === undefined) return ''
  const result = str.charAt(0).toUpperCase() + str.slice(1)
  return result
}

export interface AddNewLocationModalProps {
  showAddNewLocation: boolean
  setShowNewLocation: (show: boolean) => void
  allCountries: Country[]
  location: Location | null
}

interface IAddLocationValues {
  name: string
  description: string
  longitude: number
  latitude: number
  city: string
  country: string
}

export const AddNewLocationModal = (
  props: AddNewLocationModalProps,
): JSX.Element => {
  const {
    showAddNewLocation,
    setShowNewLocation,
    allCountries,
    location,
  } = props
  const [allCities, setCities] = useState<City[]>([])

  const handleClose = () => {
    reset()
    setShowNewLocation(false)
  }

  useEffect(() => {
    if (location) {
      setValue('name', location.name)
      setValue('description', location.description)
      setValue('latitude', location.latitude)
      setValue('longitude', location.longitude)
      setValue('country', location.country)
      setValue('city', location.city)
    }
  }, [location])

  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    longitude: yup.number().required(),
    latitude: yup.number().required(),
    country: yup.string().required(),
    city: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<IAddLocationValues>({
    mode: 'onChange',
    resolver: yupResolver<IAddLocationValues>(schema),
    defaultValues: {
      country: '',
      city: ''
    }
  })

  const submit = (values: IAddLocationValues) => {
    console.log(values)
    setShowNewLocation(false)
    reset()

  }

  const countryWatch = watch('country')

  useEffect(() => {
    if (countryWatch !== '') {
      // get all cities in a country
      setCities([{ id: '1', name: 'Cluj-Napoca' }, { id: '2', name: 'Bucuresti' }])
    }
  }, [countryWatch])

  return (
    <div className={`fixed left-0 top-0 bg-black bg-opacity-50 w-screen h-screen justify-center items-center ${showAddNewLocation ? 'flex' : 'hidden'}`} onClick={handleClose}>
      <div className="bg-white rounded shadow-md w-[798px]" onClick={(e) => { e.stopPropagation() }}>
        <div className="flex flex-col">
          <div className="border-b border-b-[#dee2e6] p-[16px] ">
            <span className="text-[20px] font-[500] leading-[24px] text-helvetica text-[#212529] tracking-[0.2px]">
              Add New Location
            </span>
          </div>
          <div className="border-b border-b-[#dee2e6] p-[16px] ">
            <form id="add-location-form" onSubmit={handleSubmit(submit)}>
              <div className='flex flex-col w-full gap-[16px]'>
                <div className='flex flex-col w-full gap-[10px]'>
                  <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Name</span>
                  <input type='text' {...register('name')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Venue Name' />
                  {(!!errors.name?.message) &&
                    <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>
                      {capFirstLetter(errors.name?.message)}
                    </span>
                  }
                </div>
                <div className='flex flex-col w-full gap-[10px]'>
                  <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Description</span>
                  <input type='text' {...register('description')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='Enter Description' />
                  {!!errors.description?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.description?.message)}</span>}
                </div>
                <div className='flex gap-[16px]'>
                  <div className='flex flex-col w-full gap-[10px]'>
                    <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Latitude</span>
                    <input type='number' step="any" {...register('latitude')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='22.3241231' />
                    {!!errors.latitude?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Latitude is a required field</span>}
                  </div>
                  <div className='flex flex-col w-full gap-[10px]'>
                    <span className='text-helvetica text-[16px] font-[700] leading-[24px] text-[#212529]'>Longitude</span>
                    <input type='number' step="any" {...register('longitude')} className='rounded border border-[#CED4DA] bg-[#F8F9FA] px-[12px] py-[6px]' placeholder='39.42312' />
                    {!!errors.longitude?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>Longitude is a required field</span>}
                  </div>
                </div>
                <div className="flex flex-col gap-[10px] w-full">
                  <span className='text-[16px] font-[700] leading-[24px] text-helvetica'>Country</span>
                  <select className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                    {...register('country')}>
                    <option disabled value={''}>Select Country</option>
                    {
                      allCountries.map(country => {
                        return (
                          <option key={country.id} value={country.id}>{country.name}</option>
                        )
                      })
                    }
                  </select>
                  {!!errors.country?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.country?.message)}</span>}
                </div>
                <div className="flex flex-col gap-[10px] w-full">
                  <span className={`text-[16px] font-[700] leading-[24px] text-helvetica`}>City</span>
                  <select disabled={countryWatch === ''} className="px-[12px] py-[6px] rounded-[4px] border border-[#CED4DA]"
                    {...register('city')}>
                    <option disabled value={''}>Select City</option>
                    {
                      allCities.map(city => {
                        return (
                          <option key={city.id} value={city.id}>{city.name}</option>
                        )
                      })
                    }
                  </select>
                  {!!errors.city?.message && <span className='text-helvetica text-[16px] font-[400] leading-[24px] text-[#DC3545]'>{capFirstLetter(errors.city?.message)}</span>}
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-between gap-[10px] p-[16px]">
            <button
              onClick={handleClose}
              className="px-[12px] py-[6px] bg-[#DEE2E6] rounded-[4px] border border-[#DEE2E6]"
            >
              Cancel
            </button>
            <button
              className="text-[16px] font-[400] rounded leading-[24px] text-[#fff] text-helvetica bg-[#6F42C1] border border-[#6F42C1] px-[12px] py-[6px]"
              form="add-location-form"
              type="submit"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
