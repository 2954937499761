import { Icon } from "../components/Icon"

export interface MenuItem {
  title: string
  path: string
  children?: MenuItem[]
  icon?: JSX.Element
  view?: boolean
  edit?: boolean
}

export const MenuItems: MenuItem[] = [
  {
    title: 'Stats',
    path: '//admin.staging.extasy.com',
    view: false,
    edit: false,
    icon: <Icon icon='stats' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Events',
    path: '//admin.staging.extasy.com/events',
    view: false,
    edit: false,
    icon: <Icon icon='events' width='14px' height='14px' viewBox="0 0 14 14" />,

  },
  {
    title: 'Banners',
    path: '//admin.staging.extasy.com/banners',
    view: false,
    edit: false,
    icon: <Icon icon='banners' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Pricing Packages',
    path: '/pricing_packages',
    view: false,
    edit: false,
    icon: <Icon icon='pricing_packages' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Experience Types',
    path: '/experience',
    view: false,
    edit: false,
    icon: <Icon icon='experience_type' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Event Venues',
    path: '/venues',
    view: false,
    edit: false,
    icon: <Icon icon='event_venue' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Orders',
    path: '/orders',
    view: false,
    edit: false,
    icon: <Icon icon='orders' width='14px' height='14px' viewBox="0 0 14 14" />,
    children: [
      {
        title: 'All',
        path: '//admin.staging.extasy.com/orders',
        view: false,
        edit: false,
      },
      {
        title: 'Failed (last 60 days)',
        path: '//admin.staging.extasy.com/orders/failed',
        view: false,
        edit: false,
      },
      {
        title: 'Neversea 2023',
        path: '//admin.staging.extasy.com/orders/neversea',
        view: false,
        edit: false,
      },
      {
        title: 'Untold 2023',
        path: '//admin.staging.extasy.com/orders/neversea',
        view: false,
        edit: false,
      },
    ],
  },
  {
    title: 'Notifications',
    path: '/notification',
    view: false,
    edit: false,
    icon: <Icon icon='notifications' width='14px' height='14px' viewBox="0 0 14 14" />,
    children: [
      {
        title: 'Details',
        path: '//admin.staging.extasy.com/notification',
        view: false,
        edit: false,
      },
      {
        title: 'Settings',
        path: '//admin.staging.extasy.com/notification/settings',
        view: false,
        edit: false,
      },
      {
        title: 'Notify all users',
        path: '//admin.staging.extasy.com/notification/send-mass-notification',
        view: false,
        edit: false,
      },
      {
        title: 'Notify specific user',
        path: '//admin.staging.extasy.com/notification/send-single-notification',
        view: false,
        edit: false,
      },
    ],
  },
  {
    title: 'Vendors',
    path: '/vendor',
    view: false,
    edit: false,
    icon: <Icon icon='vendors' width='14px' height='14px' viewBox="0 0 14 14" />,
    children: [
      {
        title: 'Register',
        path: '//admin.staging.extasy.com/vendor/register',
        view: false,
        edit: false,
      },
      {
        title: 'Details',
        path: '//admin.staging.extasy.com/vendor',
        view: false,
        edit: false,
      },
      {
        title: 'Terms&Conditions',
        path: '//admin.staging.extasy.com/vendor/terms',
        view: false,
        edit: false,
      },
    ],
  },
  {
    title: 'Users',
    path: '//admin.staging.extasy.com/users',
    view: false,
    edit: false,
    icon: <Icon icon='users' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Rewards',
    path: '/rewards',
    view: false,
    edit: false,
    icon: <Icon icon='rewards' width='14px' height='14px' viewBox="0 0 14 14" />,
    children: [
      {
        title: 'Coins',
        path: '//admin.staging.extasy.com/reward/coins',
        view: false,
        edit: false,
      },
      {
        title: 'Vouchers',
        path: '//admin.staging.extasy.com/reward/vouchers',
        view: false,
        edit: false,
      },
      {
        title: 'Reviews',
        path: '//admin.staging.extasy.com/reward/reviews',
        view: false,
        edit: false,
      },
      {
        title: 'Card Rewards',
        path: '//admin.staging.extasy.com/reward/card',
        view: false,
        edit: false,
      },
    ],
  },
  {
    title: 'Change Radius',
    path: '//admin.staging.extasy.com/change/radius',
    view: false,
    edit: false,
    icon: <Icon icon='change_radius' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Exchange Rate',
    path: '//admin.staging.extasy.com/change/rate',
    view: false,
    edit: false,
    icon: <Icon icon='change' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'StarBT to Lei',
    path: '//admin.staging.extasy.com/change/starBtToRon',
    view: false,
    edit: false,
    icon: <Icon icon='change' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Bonus - Percent',
    path: '//admin.staging.extasy.com/change/bonusPercent',
    view: false,
    edit: false,
    icon: <Icon icon='bonus' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Onboarding',
    path: '//admin.staging.extasy.com/onboarding',
    view: false,
    edit: false,
    icon: <Icon icon='onboarding' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Roadmap',
    path: '//admin.staging.extasy.com/roadmap',
    view: false,
    edit: false,
    icon: <Icon icon='roadmap' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Toggle request blocking',
    path: '//admin.staging.extasy.com/block-requests',
    view: false,
    edit: false,
    icon: <Icon icon='toggle_request_blocking' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Enterprise',
    path: '//admin.staging.extasy.com/enterprise',
    view: false,
    edit: false,
    icon: <Icon icon='enterprise' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'Redo the cache',
    path: '//admin.staging.extasy.com/redo/cache',
    view: false,
    edit: false,
    icon: <Icon icon='redo_the_cache' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
  {
    title: 'SMS Status',
    path: '//admin.staging.extasy.com/sms/status',
    view: false,
    edit: false,
    icon: <Icon icon='sms_status' width='14px' height='14px' viewBox="0 0 14 14" />,
  },
]